<template>
  <div v-gap="['', 'calc(var(--mpadding))', 'calc(var(--mpadding))', 'calc(var(--mpadding))']">
    <FeatureSoon :modal="modal" />
  </div>
</template>

<script>
export default {
  props: ["modal"],
};
</script>
